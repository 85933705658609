@import '../../styles/media-queries';

.cards {
  $cards: &;
  background: #46e1a0;
  border-radius: 4rem;
  width: 100%;
  padding: 2.8rem 0;
  box-sizing: border-box;
  cursor: move;

  @supports (cursor: grab) {
    cursor: grab;
  }

  &:active {
    @supports (cursor: grabbing) {
      cursor: grabbing;
    }
  }

  @supports (cursor: grab) {
    cursor: grab;
  }

  &:active {
    @supports (cursor: grabbing) {
      cursor: grabbing;
    }
  }

  @include for-screen-up(640) {
    background: none;
    padding: 0;
  }

  &__splide {
    @include for-screen-up(960) {
      display: flex;
      justify-content: center;
    }
  }

  &__track {
    overflow: visible;
    max-width: 100%;
    box-sizing: border-box;

    @include for-screen-up(960) {
      flex: 0 1 auto;
    }
  }

  &__list {
    display: flex;
    width: 100%;
  }

  &__track {
    margin: -3.25rem 0 0;
    padding: 3.25rem 0;
  }

  &__item {
    background: var(--bg-color-primary-lightgrey);
    border-radius: 2.4rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translateY(-3.25rem);
    opacity: 0;
    transition: transform 1s ease 0.3s, opacity 1s ease 0.3s;
    will-change: transform, opacity;

    &:nth-child(2n) {
      transform: translateY(3.25rem);

      #{$cards}_animation-start & {
        transform: translateY(0);
        opacity: 1;
      }
    }

    #{$cards}_animation-start & {
      transform: translateY(0);
      opacity: 1;
    }

    @include for-screen-up(960) {
      border-radius: 2.9rem;
    }

    @include for-screen-up(1024) {
      border-radius: 3.1rem;
    }
  }

  &__fig {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    max-width: 50%;
  }

  img,
  svg {
    transform: scale(2.1);

    @include for-screen-up(960) {
      transform: scale(2.7);
    }

    @include for-screen-up(1024) {
      transform: scale(3);
    }
  }

  &__card {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 150%;
  }

  &__name {
    color: var(--text-color-primary-black);
    line-height: 1.36;
    position: absolute;
    bottom: 3.6rem;
    left: 0;
    right: 0;
    font-size: 1.1rem;
    text-align: center;

    @include for-screen-up(640) {
      bottom: 3.5rem;
    }

    @include for-screen-up(960) {
      font-size: 1.2rem;
      bottom: 4.3rem;
    }

    @include for-screen-up(1024) {
      font-size: 1.3rem;
      bottom: 4.6rem;
    }

    @include for-screen-up(1366) {
      font-size: 1.5rem;
      bottom: 4.7rem;
    }
  }

  &__splide-pagination {
    gap: 0.7rem;
    display: flex !important;
  }

  &__splide-page {
    background: var(--color-stroke-2);
    border: 0;
    border-radius: 50%;
    width: 0.6rem;
    height: 0.6rem;
    padding: 0;

    &[class*='is-active'] {
      background: #1573ef;
      width: 2.6rem;
      border-radius: 0.3rem;
    }
  }
}
