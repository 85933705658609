@import '../../styles/media-queries';

.networks {
  background-color: var(--color-secondary-103);
  margin: 3.6rem 0 0;
  padding: 8.1rem 0 7.5rem;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @include for-screen-up(640) {
    padding: 8.1rem 1.8rem 12.7rem;
  }

  @include for-screen-up(960) {
    padding: 12.2rem 1.8rem 8.3rem;
  }

  @include for-screen-up(1024) {
    padding: 12.2rem 1.8rem 14.2rem;
  }

  @include for-screen-up(1680) {
    margin: 5.6rem 0 0;
    padding: 13rem 1.8rem 14.7rem;
  }

  &:after,
  &:before {
    content: '';
    height: 8.1rem;
    position: absolute;
    top: 0;
    left: -2rem;
    right: -2rem;
    z-index: -1;

    @include for-screen-up(1680) {
      height: 13rem;
      left: -3rem;
      right: -3rem;
    }
  }

  &:before {
    background-color: var(--color-secondary-103);
    border-radius: 14rem 14rem 0 0;
  }

  &:after {
    background-color: var(--bg-color-primary-white);
    z-index: -2;
  }

  &__inner {
    max-width: 147rem;
    margin: auto;
  }

  &__heading {
    max-width: 90rem;
    margin: 0 auto 2.5rem;
    color: var(--text-color-primary-black);
    font-size: 2.9rem;
    line-height: 1.4;
    text-align: center;

    @include for-screen-up(960) {
      font-size: 3.6rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 3.5rem;
      font-size: 4.5rem;
    }

    @include for-screen-up(1366) {
      font-size: 5rem;
      max-width: 100rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 1.5rem;
      font-size: 4rem;
    }

    @include for-screen-up(1920) {
      max-width: 120rem;
      margin-bottom: 5.9rem;
      font-size: 6.5rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 37.5rem;
    margin: auto;

    @include for-screen-up(768) {
      max-width: none;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include for-screen-up(960) {
      gap: 1.5rem;
      justify-content: flex-start;
    }

    @include for-screen-up(1280) {
      gap: 1.6rem;
    }

    @include for-screen-up(1366) {
      gap: 2.2rem;
    }

    @include for-screen-up(1680) {
      gap: 3rem;
    }
  }

  &__list-item {
    @include for-screen-up(768) {
      flex: 0 1 calc(50% - 1.5rem);
      max-width: calc(50% - 1.5rem);
    }

    @include for-screen-up(960) {
      flex: 0 1 calc(33.333% - 1rem);
      max-width: calc(33.333% - 1rem);
    }

    @include for-screen-up(1280) {
      flex: 0 1 calc(33.333% - 1.0666rem);
      max-width: calc(33.333% - 1.0666rem);
    }

    @include for-screen-up(1366) {
      flex: 0 1 calc(33.333% - 1.4666rem);
      max-width: calc(33.333% - 1.4666rem);
    }

    @include for-screen-up(1680) {
      flex: 0 1 calc(33.333% - 2rem);
      max-width: calc(33.333% - 2rem);
    }
  }
}
