@import '../../styles/media-queries';

.section {
  overflow: hidden;
  position: relative;
  margin-bottom: 6.7rem;

  @include for-screen-up(640) {
    margin-bottom: 5rem;
  }

  @include for-screen-up(1024) {
    margin-bottom: 12rem;
  }

  &__inner {
    background-color: var(--bg-color-primary-white);
    margin: 0 -2.1rem;
    padding: 8.1rem 3.9rem 0;

    @include for-screen-up(640) {
      margin: 0 -3rem;
      padding: 8.1rem 6rem 0;
    }

    @include for-screen-up(1024) {
      padding: 8.1rem 6rem 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    margin: 0 0 1rem;

    color: var(--text-color-primary-black);
    font-size: 4rem;

    line-height: 1.36;
    text-align: center;

    @include for-screen-up(360) {
      font-size: 5rem;
    }

    @include for-screen-up(640) {
      width: 38.1rem;
      font-size: 5rem;
      margin-bottom: 1rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 0.5rem;
      width: 51.6rem;
      font-size: 6rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 1rem;
      width: 55rem;
      font-size: 6.5rem;
    }

    @include for-screen-up(1680) {
      font-size: 7.5rem;
    }
  }

  &__summary {
    margin-bottom: 3.5rem;
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;

    line-height: 1.7;
    text-align: center;

    @include for-screen-up(360) {
      font-size: 2rem;
    }

    @include for-screen-up(640) {
      margin: 0 0 3.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      width: 60.9rem;
      margin: 0 0 2.5rem;
      font-size: 1.8rem;
    }

    @include for-screen-up(1024) {
      width: 65rem;
      margin: 0 0 2.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(1680) {
      margin: 0 0 4rem;
      font-size: 2.1rem;
    }
  }

  &__tabs {
    align-self: flex-start;

    margin-bottom: 1.6rem;

    @include for-screen-up(360) {
      align-self: center;
    }

    @include for-screen-up(640) {
      margin-bottom: 4.5rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 5.2rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 4.8rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 5.4rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 7rem;
    }
  }

  &__tab {
    display: none;
    width: calc(100% + 3.6rem);
    margin: 0 -1.8rem;

    &_active {
      display: block;
    }

    @include for-screen-up(640) {
      width: calc(100% + 5.2rem);
      margin: 0 -2.6rem;
    }

    @include for-screen-up(1024) {
      width: calc(100% + 5.6rem);
      margin: 0 -2.8rem;
    }
  }
}
