@import '../../styles/media-queries';
@import '../../styles/functions';

.benefits-card {
  $card: &;
  background: no-repeat 50% 0;
  background-size: 100% auto;

  display: inline-flex;

  border-radius: 3.2rem;

  flex-direction: column;
  box-sizing: border-box;

  @include for-screen-up(960) {
    border-radius: 4rem;

    @supports (container-type: size) {
      border-radius: pxTOcqw(40);
    }
  }

  @include for-screen-up(1680) {
    border-radius: 4rem;
  }
  &_with-animation {
    clip-path: inset(50% 50% 50% 50%);
    will-change: clip-path;
    transition: clip-path 0.5s ease;
  }

  [class*='animation-begin'] &,
  [class*='animation-ready'] [class*='animation-start'] & {
    clip-path: inset(0 0 0 0 round 3.2rem);

    @include for-screen-up(960) {
      clip-path: inset(0 0 0 0 round 4rem);

      @supports (container-type: size) {
        clip-path: inset(0 0 0 0 round pxTOcqw(40));
      }
    }

    @include for-screen-up(1680) {
      clip-path: inset(0 0 0 0 round 4rem);
    }
  }

  &_report {
    background-color: #1e7a48;
    justify-content: flex-end;
    width: 21rem;
    height: 29.3rem;
    padding: 2.2rem 2.5rem;
    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 25.6rem;
      height: 35.7rem;
      padding: 3.5rem 2.9rem 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(256);
        height: pxTOcqw(357);
        padding: pxTOcqw(35) pxTOcqw(29) pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 25.6rem;
      height: 35.7rem;
      padding: 3.5rem 2.9rem 3.5rem 4rem;
    }

    & #{$card}__summary {
      font-family: Lack, sans-serif;
      font-size: 1.5rem;
      line-height: 1.36;

      @include for-screen-up(960) {
        font-size: 1.9rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(19);
        }
      }

      @include for-screen-up(1680) {
        font-size: 1.9rem;
      }
    }
  }

  &_ctr {
    background-color: #fff;
    width: 23rem;
    height: 29.3rem;
    color: var(--text-color-primary-black);
    justify-content: flex-end;
    padding: 2.2rem 1.9rem 2.2rem 2.5rem;

    @include for-screen-up(960) {
      width: 32rem;
      height: 40.8rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(320);
        height: pxTOcqw(408);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 32rem;
      height: 40.8rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      margin-bottom: 0.3rem;
      font-family: Lack, sans-serif;
      font-size: 1.9rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        font-size: 2.5rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(25);
        }
      }

      @include for-screen-up(1680) {
        font-size: 2.5rem;
      }
    }

    & #{$card}__summary {
      font-family: Gilroy, sans-serif;
      font-size: 1.4rem;
      line-height: 1.7;

      @include for-screen-up(960) {
        font-size: 1.8rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(18);
        }
      }

      @include for-screen-up(1680) {
        font-size: 1.8rem;
      }
    }
  }

  &_filters {
    background-color: var(--bg-color-primary-white);
    background-position: 50% 100%;
    width: 25rem;
    height: 29.3rem;
    padding: 2.2rem 2.5rem 0;

    color: var(--text-color-primary-black);

    @include for-screen-up(960) {
      width: 30.1rem;
      height: 35.3rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(301);
        height: pxTOcqw(353);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 30.1rem;
      height: 35.3rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__summary {
      margin-bottom: 1.8rem;
      font-family: Gilroy, sans-serif;
      font-size: 1.5rem;
      line-height: 1.7;

      @include for-screen-up(960) {
        margin-bottom: 2.2rem;
        font-size: 1.8rem;

        @supports (container-type: size) {
          margin-bottom: pxTOcqw(22);
          font-size: pxTOcqw(18);
        }
      }

      @include for-screen-up(1680) {
        margin-bottom: 2.2rem;
        font-size: 1.8rem;
      }
    }
  }

  &_vat {
    background-color: #1380ff;
    background-position: 50% 100%;
    width: 18.7rem;
    height: 29.3rem;
    padding: 2.2rem 2.5rem;

    justify-content: flex-start;
    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 29.3rem;
      height: 40.5rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(293);
        height: pxTOcqw(405);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 29.3rem;
      height: 40.5rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      font-family: Lack, sans-serif;
      font-size: 1.5rem;
      line-height: 1.36;

      @include for-screen-up(960) {
        font-size: 2.1rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(21);
        }
      }

      @include for-screen-up(1680) {
        font-size: 2.1rem;
      }
    }
  }

  &_tiktok {
    background-color: var(--bg-color-primary-black);
    width: 25.5rem;
    height: 29.3rem;
    color: var(--text-color-primary-white);
    justify-content: flex-end;
    padding: 2.2rem 2.5rem;

    @include for-screen-up(960) {
      width: 29.9rem;
      height: 34.3rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(299);
        height: pxTOcqw(343);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 29.9rem;
      height: 34.3rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      width: 16.5rem;
      font-size: 1.9rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        width: 19.3rem;
        font-size: 2.1rem;
        line-height: 1.36;

        @supports (container-type: size) {
          width: pxTOcqw(193);
          font-size: pxTOcqw(21);
        }
      }
      @include for-screen-up(1680) {
        width: 19.3rem;
        font-size: 2.1rem;
      }
    }
  }

  &_budget {
    background-color: #000;
    justify-content: flex-end;
    width: 26.7rem;
    height: 29.3rem;
    padding: 2.2rem 2.5rem;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 32.1rem;
      height: 38.4rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(321);
        height: pxTOcqw(384);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 32.1rem;
      height: 38.4rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__summary {
      width: 15.8rem;
      flex: 0 1 auto;
      font-family: Gilroy, sans-serif;
      font-size: 1.4rem;
      line-height: 1.7;

      @include for-screen-up(960) {
        width: 20.3rem;
        font-size: 1.9rem;

        @supports (container-type: size) {
          width: pxTOcqw(203);
          font-size: pxTOcqw(19);
        }
      }
      @include for-screen-up(1680) {
        width: 20.3rem;
        font-size: 1.9rem;
      }
    }
  }

  &_adv {
    background-color: #fff;
    background-position: 50% 100%;
    width: 23.5rem;
    height: 29.3rem;
    padding: 2.2rem 2.5rem;

    color: var(--text-color-primary-black);

    @include for-screen-up(960) {
      width: 35.7rem;
      height: 47.2rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(357);
        height: pxTOcqw(472);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 35.7rem;
      height: 47.2rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__summary {
      flex: 0 1 auto;
      font-family: Gilroy, sans-serif;
      font-size: 1.4rem;
      line-height: 1.7;

      @include for-screen-up(960) {
        font-size: 1.8rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(18);
        }
      }

      @include for-screen-up(1680) {
        font-size: 1.8rem;
      }
    }
  }

  &_networks {
    background-color: #6d7bff;
    width: 25rem;
    height: 29.3rem;
    padding: 2.2rem 2.5rem;
    justify-content: flex-end;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 31.1rem;
      height: 44.4rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(311);
        height: pxTOcqw(444);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 31.1rem;
      height: 44.4rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      margin-bottom: 0.3rem;
      font-size: 1.9rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        font-size: 2.5rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(25);
        }
      }

      @include for-screen-up(1680) {
        font-size: 2.5rem;
      }
    }

    & #{$card}__summary {
      width: 17rem;
      font-family: Gilroy, sans-serif;
      font-size: 1.4rem;
      line-height: 1.7;

      @include for-screen-up(960) {
        width: auto;
        font-size: 1.8rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(18);
        }
      }

      @include for-screen-up(1680) {
        font-size: 1.8rem;
      }
    }
  }

  &_promised-payment-1 {
    background-color: #000;
    width: 22.98rem;
    height: 29.3rem;
    padding: 2.8rem;
    justify-content: flex-end;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 32rem;
      height: 40.8rem;
      padding: 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(320);
        height: pxTOcqw(408);
        padding: pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 32rem;
      height: 40.8rem;
      padding: 4rem;
    }

    & #{$card}__heading {
      font-size: 1.8rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        font-size: 2.5rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(25);
        }
      }

      @include for-screen-up(1680) {
        font-size: 2.5rem;
      }
    }
  }

  &_pay {
    background-color: #6d7bff;
    width: 24.9rem;
    height: 29.3rem;
    justify-content: flex-end;

    padding: 2.2rem 2.5rem;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 30.3rem;
      height: 35.9rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(303);
        height: pxTOcqw(359);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 30.3rem;
      height: 35.9rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      width: 16.4rem;
      font-size: 1.9rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        width: 18.9rem;

        font-size: 2.1rem;
        line-height: 1.36;

        @supports (container-type: size) {
          width: pxTOcqw(189);

          font-size: pxTOcqw(21);
        }
      }

      @include for-screen-up(1680) {
        width: 18.9rem;

        font-size: 2.1rem;
      }
    }
  }

  &_promised-payment-2 {
    background-color: #1380ff;
    background-position: 100% 100%;
    background-size: 75%;
    width: 22.98rem;
    height: 29.3rem;
    padding: 2.8rem;
    justify-content: flex-start;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 32rem;
      height: 40.8rem;
      padding: 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(320);
        height: pxTOcqw(408);
        padding: pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 32rem;
      height: 40.8rem;
      padding: 4rem;
    }

    & #{$card}__heading {
      font-size: 1.8rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        font-size: 2.5rem;
        line-height: 1.4;

        @supports (container-type: size) {
          font-size: pxTOcqw(25);
        }
      }

      @include for-screen-up(1680) {
        font-size: 2.5rem;
      }
    }
  }

  &_white-label {
    background-color: var(--bg-color-primary-black);
    background-position: 50% 100%;
    width: 23.5rem;
    height: 29.3rem;

    padding: 2.2rem 2.5rem;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 35.7rem;
      height: 47.2rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(357);
        height: pxTOcqw(472);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 35.7rem;
      height: 47.2rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      font-size: 1.9rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        font-size: 2.5rem;

        @supports (container-type: size) {
          font-size: pxTOcqw(25);
        }
      }
      @include for-screen-up(1680) {
        font-size: 2.5rem;
      }
    }
  }

  &_cabinet {
    background-color: var(--bg-color-primary-black);
    width: 22.9rem;
    height: 29.3rem;

    padding: 2.2rem 2.5rem;
    justify-content: flex-end;

    color: var(--text-color-primary-white);

    @include for-screen-up(960) {
      width: 30.6rem;
      height: 39.1rem;
      padding: 3.5rem 4rem;

      @supports (container-type: size) {
        width: pxTOcqw(306);
        height: pxTOcqw(391);
        padding: pxTOcqw(35) pxTOcqw(40);
      }
    }

    @include for-screen-up(1680) {
      width: 30.6rem;
      height: 39.1rem;
      padding: 3.5rem 4rem;
    }

    & #{$card}__heading {
      margin-bottom: 0.3rem;
      font-size: 1.9rem;
      line-height: 1.4;

      @include for-screen-up(960) {
        font-size: 2.3rem;
        line-height: 1.36;

        @supports (container-type: size) {
          font-size: pxTOcqw(23);
        }
      }

      @include for-screen-up(1680) {
        font-size: 2.3rem;
      }
    }

    & #{$card}__summary {
      width: 14rem;
      font-family: Gilroy, sans-serif;
      font-size: 1.4rem;
      line-height: 1.7;

      @include for-screen-up(960) {
        font-size: 1.7rem;

        @supports (container-type: size) {
          width: pxTOcqw(140);
          font-size: pxTOcqw(17);
        }
      }

      @include for-screen-up(1680) {
        width: 14rem;
        font-size: 1.7rem;
      }
    }
  }
}
