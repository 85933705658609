@import '../../styles/media-queries';

.tooltip-trigger {
  $root: &;

  display: inline-flex;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  justify-content: center;
  align-items: center;
}

.tooltip {
  background-color: #5e5e5e;
  border-radius: 0.7rem;
  width: 18.3rem;
  padding: 0.7rem 1.5rem;
  position: absolute;
  z-index: 999;
  color: var(--text-color-primary-white);
  font-size: 1.3rem;
  line-height: 1.3;
  font-family: Rubik, sans-serif;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
  text-align: center;
  /*transform: translate(-50%, -100%);*/

  &_shown {
    opacity: 1;
    visibility: visible;
  }

  @include for-screen-up(640) {
  }
}
