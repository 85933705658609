@import '../../styles/media-queries';

.scroll-table {
  $table: &;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;

  &__table {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    color: var(--text-color-primary-white);
    font-family: Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.25;
    max-width: 100%;
  }

  &__head-column {
    background: var(--color-secondary-93);

    min-width: 9.7rem;
    max-width: 9.7rem;
    padding: 0 0.7rem;

    @include for-screen-up(640) {
      min-width: 20rem;
      max-width: 20rem;
    }

    @include for-screen-up(1024) {
      min-width: 21.4rem;
      max-width: 21.4rem;
    }

    @include for-screen-up(1366) {
      min-width: 22.4rem;
      max-width: 22.4rem;
    }

    @include for-screen-up(1680) {
      max-width: 27rem;
      min-width: 27rem;
    }

    #{$table}__cell {
      justify-content: flex-start;
      font-size: 0.8rem;
      line-height: 1.3;

      @include for-screen-up(640) {
        padding: 0 4rem;
        font-size: 1.2rem;
      }

      @include for-screen-up(1680) {
        font-size: 1.6rem;
        padding: 0 5.3rem;
      }

      &:nth-child(3) ~ #{$table}__cell {
        font-family: Arial, sans-serif;
        font-size: 1rem;

        @include for-screen-up(640) {
          font-size: 1.2rem;
        }

        @include for-screen-up(1024) {
          font-size: 1.3rem;
        }

        @include for-screen-up(1680) {
          font-size: 1.6rem;
        }

        &:before {
          left: 1.3rem;

          margin-left: 0;

          @include for-screen-up(640) {
            left: 4rem;
          }

          @include for-screen-up(1680) {
            left: 5.3rem;
          }
        }
      }
    }
  }

  &__splide-column {
    max-width: calc(100% - 8.1rem);
    cursor: move;

    @supports (cursor: grab) {
      cursor: grab;
    }

    &:active {
      @supports (cursor: grabbing) {
        cursor: grabbing;
      }
    }

    @supports (cursor: grab) {
      cursor: grab;
    }

    &:active {
      @supports (cursor: grabbing) {
        cursor: grabbing;
      }
    }

    @include for-screen-up(640) {
      max-width: calc(100% - 22rem);
    }

    @include for-screen-up(1680) {
      max-width: calc(100% - 27rem);
    }
  }

  &__splide {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    @include for-screen-up(640) {
      gap: 3.7rem;
    }
  }

  &__column {
    box-sizing: border-box;

    &[data-index='0'] {
      background: var(--color-secondary-92);
      border-radius: 2.1rem;

      @include for-screen-up(1366) {
        border-radius: 3rem;
      }

      @include for-screen-up(1680) {
        border-radius: 3.6rem;
      }
    }
  }

  &__cell {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 1;

    &_hover {
      &:after {
        content: '';
        background: rgba(255, 255, 255, 0.04);
        position: absolute;
        top: 0;
        left: -0.29rem;
        right: -0.29rem;
        bottom: 0;
        z-index: -1;

        @include for-screen-up(640) {
          left: -0.79rem;
          right: -0.79rem;
        }

        @include for-screen-up(1366) {
          left: -0.89rem;
          right: -0.89rem;
        }

        @include for-screen-up(1680) {
          left: -1.01rem;
          right: -1.01rem;
        }

        #{$table}__head-column & {
          right: -0.7rem;
        }
      }
    }

    &:nth-child(1) {
      height: 9rem;
      align-items: flex-end;

      @include for-screen-up(640) {
        height: 9.4rem;
      }

      @include for-screen-up(960) {
        height: 9.4rem;
      }

      @include for-screen-up(1024) {
        height: 9.4rem;
      }

      @include for-screen-up(1366) {
        height: 9.4rem;
      }

      @include for-screen-up(1680) {
        height: 9.4rem;
      }
    }

    &:nth-child(2) {
      height: 1.5rem;
      font-family: Gilroy, sans-serif;
      font-size: 0.8rem;
      line-height: 1.3;
      text-align: center;
      align-items: center;

      @include for-screen-up(640) {
        height: 1.8rem;
        font-size: 1.1rem;
      }

      @include for-screen-up(1366) {
        height: 5rem;
        font-size: 1.4rem;
      }
    }

    &:nth-child(3) {
      height: 6.3rem;

      @include for-screen-up(640) {
        height: 7.5rem;
      }

      @include for-screen-up(1024) {
        height: 8rem;
      }

      @include for-screen-up(1366) {
        height: 8.5rem;
      }

      @include for-screen-up(1680) {
        height: 5.4rem;
      }
    }

    &:nth-child(3) ~ #{$table}__cell {
      height: 4.2rem;

      @include for-screen-up(640) {
        height: 4.5rem;
      }

      &:before {
        content: '';
        background: rgba(255, 255, 255, 0.08);
        width: 4.2rem;
        height: 0.1rem;
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        @include for-screen-up(960) {
          width: 5.2rem;
          height: 0.1rem;
        }

        @include for-screen-up(960) {
          width: 5.9rem;
          height: 0.1rem;
        }

        @include for-screen-up(1366) {
          width: 5.9rem;
          height: 0.1rem;
        }

        @include for-screen-up(1680) {
          width: 7rem;
          height: 0.1rem;
        }
      }

      &:nth-last-child(2) {
        &:before {
          display: none;
        }
      }

      &:last-child {
        height: 8.5rem;

        @include for-screen-up(640) {
          height: 9.5rem;
        }

        @include for-screen-up(1366) {
          height: 11.4rem;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.2rem;
    height: 4.5rem;
    position: relative;

    @include for-screen-up(640) {
      width: 6.5rem;
      height: 5.5rem;
    }
  }

  &__logo-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);

    @include for-screen-up(640) {
      transform: translate(-50%, -50%) scale(0.9);
    }

    @include for-screen-up(1024) {
      transform: translate(-50%, -50%) scale(0.95);
    }

    @include for-screen-up(1366) {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &__value {
    font-family: Arial, sans-serif;
    font-size: 1.1rem;
    line-height: 1;

    @include for-screen-up(640) {
      font-size: 1.3rem;
    }

    @include for-screen-up(1024) {
      font-size: 1.4rem;
    }

    @include for-screen-up(1366) {
      font-size: 1.5rem;
    }

    @include for-screen-up(1366) {
      font-size: 1.8rem;
    }

    #{$table}__value-unit_with-info & {
      font-size: 1.1rem;

      @include for-screen-up(640) {
        font-size: 1.3rem;
      }

      @include for-screen-up(1024) {
        font-size: 1.4rem;
      }
    }
  }

  &__unit {
    font-family: Arial, sans-serif;
    font-size: 0.8rem;
    line-height: 1.15;

    @include for-screen-up(640) {
      font-size: 1rem;
    }

    @include for-screen-up(1366) {
      font-size: 1.1rem;
    }

    @include for-screen-up(1680) {
      font-size: 1.3rem;
    }

    #{$table}__value-unit_with-info & {
      font-size: 1.1rem;

      @include for-screen-up(640) {
        font-size: 1.3rem;
      }

      @include for-screen-up(1024) {
        font-size: 1.4rem;
      }
    }
  }

  &__value-unit {
    display: flex;
    align-items: flex-end;
    gap: 0.2rem;
  }

  &__splide-arrows {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &__arrows {
    display: flex;
    width: 100%;
    flex: 1 1 100%;
    padding-top: 2.2rem;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    @include for-screen-up(640) {
      padding-top: 1.2rem;
    }

    @include for-screen-up(960) {
      padding-top: 1.4rem;
    }

    @include for-screen-up(1024) {
      padding-top: 1.9rem;
    }

    @include for-screen-up(1366) {
      padding-top: 2.2rem;
    }

    @include for-screen-up(1680) {
      padding-top: 1.9rem;
    }

    @include for-screen-up(1920) {
      padding-top: 3.7rem;
    }

    @include for-screen-up(2560) {
      padding-top: 3.2rem;
    }

    @include for-screen-up(1680) {
      gap: 1.8rem;
    }
  }

  &__arrow {
    background: rgba(19, 128, 255, 0.9);
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.9rem;
    width: 7.5rem;
    height: 5.6rem;
    position: relative;
    padding: 0;
    transform: scale(1);

    transition: background-color 0.3s ease, transform 0.1s linear;

    @include for-screen-up(1024) {
      border-radius: 2rem;
      width: 8rem;
      height: 6rem;
    }

    @include for-screen-up(1680) {
      border-radius: 2.3rem;
      width: 9rem;
      height: 6.7rem;
    }

    &:hover {
      background-color: rgba(19, 128, 255, 1);
      transform: scale(1.02);
    }

    &:disabled {
      background: rgba(19, 128, 255, 0.3);
      cursor: not-allowed;
      pointer-events: none;
    }

    &:active {
      &:hover {
        transform: scale(1.06);
      }
    }

    @include for-screen-up(1024) {
      width: 8rem;
      height: 6rem;
    }

    @include for-screen-up(1680) {
      width: 9rem;
      height: 6.7rem;
    }
  }

  &__button {
    @media (max-width: 639px) {
      transform: scale(0.66);
    }
  }

  &__info {
    top: 0.5rem;
    margin-top: -1rem;
    margin-left: 0.3rem;
  }
}
