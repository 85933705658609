@import '../../styles/media-queries';
@import '../../styles/functions';

.possibilities {
  $root: &;
  border-radius: 4rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 4.3rem 0 5.1rem;
  box-sizing: border-box;

  @include for-screen-up(640) {
    padding: 3.9rem 0;
    border-radius: 6rem;
  }

  @include for-screen-up(960) {
    border-radius: 6rem;
    height: 76.2rem;
    padding: 0;
    flex-direction: row;
    gap: 0;
    align-items: center;

    @supports (container-type: size) {
      container-type: size;
      height: pxTOcqw(762);
      border-radius: pxTOcqw(60);
    }
  }

  @include for-screen-up(1680) {
    border-radius: 6rem;
    height: 76.2rem;
  }

  &__column {
    flex: 0 1 auto;
    max-width: 100%;
    position: relative;

    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;

      #{$root}__links {
        display: none;

        @include for-screen-up(960) {
          display: flex;
          flex-direction: row;
          gap: 1.6rem;
          margin-top: 4rem;

          #{$root}_type-white-label & {
            align-items: flex-start;
            flex-direction: column;
          }

          @supports (container-type: size) {
            border-radius: pxTOcqw(40);
          }
        }

        @include for-screen-up(960) {
          gap: 2.1rem;

          @supports (container-type: size) {
            margin-top: pxTOcqw(21);
          }
        }

        @include for-screen-up(1680) {
          gap: 2.1rem;
        }
      }

      @include for-screen-up(960) {
        flex: 0 1 50%;
        min-width: 50%;
      }
    }

    &:last-child {
      width: 100%;

      #{$root}__links {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        margin-top: 2.1rem;
        padding: 0 1.8rem;

        @include for-screen-up(640) {
          padding: 0 6rem;
          flex-direction: row;
          justify-content: flex-start;

          #{$root}_type-white-label & {
            flex-direction: column;
          }
        }

        @include for-screen-up(960) {
          display: none;
        }
      }

      @include for-screen-up(640) {
        width: auto;
      }

      @include for-screen-up(960) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 50%;
        min-width: 50%;
      }
    }
  }

  &__heading {
    margin: 0 0 1rem;
    color: var(--text-color-primary-black);
    font-size: 3rem;
    line-height: 1.36;

    @include for-screen-up(360) {
      font-size: 3.6rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 1.5rem;
      font-size: 5.3rem;

      @supports (container-type: size) {
        font-size: pxTOcqw(53);
        margin-bottom: pxTOcqw(20);
      }
    }

    @include for-screen-up(1680) {
      margin-bottom: 1.5rem;
      font-size: 5.3rem;
    }
  }

  &__summary {
    color: var(--text-color-primary-black);
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;
    line-height: 1.7;

    @include for-screen-up(960) {
      font-size: 2.1rem;

      @supports (container-type: size) {
        font-size: pxTOcqw(21);
      }
    }

    @include for-screen-up(1680) {
      font-size: 2.1rem;
    }
  }

  &__content {
    padding: 0 1.8rem;

    @include for-screen-up(640) {
      padding: 0 6rem;
    }

    @include for-screen-up(960) {
      padding: 0;
      width: 46.8rem;

      @supports (container-type: size) {
        width: pxTOcqw(468);
      }
    }

    @include for-screen-up(1680) {
      width: 46.8rem;
    }
  }

  &__list {
    width: 100%;
    margin: 2.5rem 0 4rem;
    padding: 0 1.8rem;
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    gap: 1.3rem;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      background: transparent;
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-button {
      background: transparent;
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    #{$root}_type4 & {
      justify-content: center;

      @include for-screen-up(640) {
        justify-content: flex-start;
      }
    }

    @include for-screen-up(640) {
      padding: 0 6rem;
    }

    @include for-screen-up(960) {
      margin: 0;
      padding: 0;
      overflow: visible;
      gap: 0;
      min-width: 71.2rem;
      max-width: 71.2rem;
      height: 66.4rem;
      position: relative;

      @supports (container-type: size) {
        min-width: pxTOcqw(712);
        max-width: pxTOcqw(712);
        height: pxTOcqw(664);
      }
    }

    @include for-screen-up(1680) {
      min-width: 71.2rem;
      max-width: 71.2rem;
      height: 66.4rem;
    }
  }

  &__button {
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }

  &__item {
    display: block;
    margin: 0;
    padding: 0;

    @include for-screen-up(960) {
      position: absolute;
    }

    #{$root}_type-platform & {
      &:nth-child(1) {
        @include for-screen-up(960) {
          top: 4.7rem;
          left: -13.4rem;
          z-index: 2;

          @supports (container-type: size) {
            top: pxTOcqw(47);
            left: pxTOcqw(-134);
          }
        }
        @include for-screen-up(1680) {
          top: 4.7rem;
          left: -13.4rem;
        }
      }

      &:nth-child(2) {
        @include for-screen-up(960) {
          top: 5.8rem;
          left: 40.9rem;
          z-index: 1;

          @supports (container-type: size) {
            top: pxTOcqw(58);
            left: pxTOcqw(409);
          }
        }

        @include for-screen-up(1680) {
          top: 5.8rem;
          left: 40.9rem;
        }
      }

      &:nth-child(3) {
        @include for-screen-up(960) {
          top: 32.5rem;
          left: 13.4rem;
          z-index: 3;

          @supports (container-type: size) {
            top: pxTOcqw(325);
            left: pxTOcqw(134);
          }
        }

        @include for-screen-up(1680) {
          top: 32.5rem;
          left: 13.4rem;
        }
      }
    }

    #{$root}_type-statistics & {
      &:nth-child(1) {
        @include for-screen-up(960) {
          top: 2rem;
          left: -12.3rem;
          z-index: 3;

          @supports (container-type: size) {
            top: pxTOcqw(2);
            left: pxTOcqw(-123);
          }
        }
        @include for-screen-up(1680) {
          top: 2rem;
          left: -12.3rem;
        }
      }

      &:nth-child(2) {
        @include for-screen-up(960) {
          top: 26.3rem;
          left: 8.8rem;
          z-index: 1;

          @supports (container-type: size) {
            top: pxTOcqw(263);
            left: pxTOcqw(88);
          }
        }

        @include for-screen-up(1680) {
          top: 26.3rem;
          left: 8.8rem;
        }
      }

      &:nth-child(3) {
        @include for-screen-up(960) {
          left: 35rem;
          top: 0.8rem;
          z-index: 2;

          @supports (container-type: size) {
            left: pxTOcqw(350);
            top: pxTOcqw(8);
          }
        }
        @include for-screen-up(1680) {
          left: 35rem;
          top: 0.8rem;
        }
      }
    }

    #{$root}_type-services & {
      &:nth-child(1) {
        @include for-screen-up(960) {
          left: -9.4rem;
          top: 6.2rem;
          z-index: 1;

          @supports (container-type: size) {
            left: pxTOcqw(-94);
            top: pxTOcqw(62);
          }
        }
        @include for-screen-up(1680) {
          left: -9.4rem;
          top: 6.2rem;
        }
      }

      &:nth-child(2) {
        @include for-screen-up(960) {
          top: 19rem;
          left: 25.4rem;
          z-index: 3;

          @supports (container-type: size) {
            top: pxTOcqw(190);
            left: pxTOcqw(254);
          }
        }

        @include for-screen-up(1680) {
          top: 19rem;
          left: 25.4rem;
        }
      }
    }

    #{$root}_type-cpa & {
      &:nth-child(1) {
        @include for-screen-up(960) {
          top: 3.7rem;
          left: -9.7rem;
          z-index: 3;

          @supports (container-type: size) {
            top: pxTOcqw(37);
            left: pxTOcqw(-97);
          }
        }

        @include for-screen-up(1680) {
          top: 3.7rem;
          left: -9.7rem;
        }
      }

      &:nth-child(2) {
        @include for-screen-up(960) {
          top: 26rem;
          left: 25.4rem;
          z-index: 1;

          @supports (container-type: size) {
            top: pxTOcqw(260);
            left: pxTOcqw(254);
          }
        }

        @include for-screen-up(1680) {
          top: 26rem;
          left: 25.4rem;
        }
      }
    }

    #{$root}_type-promised-payment & {
      &:nth-child(1) {
        @include for-screen-up(960) {
          top: 3.7rem;
          left: -9.7rem;
          z-index: 3;

          @supports (container-type: size) {
            top: pxTOcqw(37);
            left: pxTOcqw(-97);
          }
        }

        @include for-screen-up(1680) {
          top: 3.7rem;
          left: -9.7rem;
        }
      }

      &:nth-child(2) {
        @include for-screen-up(960) {
          top: 26rem;
          left: 25.4rem;
          z-index: 1;

          @supports (container-type: size) {
            top: pxTOcqw(260);
            left: pxTOcqw(254);
          }
        }

        @include for-screen-up(1680) {
          top: 26rem;
          left: 25.4rem;
        }
      }
    }

    #{$root}_type-white-label & {
      &:nth-child(1) {
        @include for-screen-up(960) {
          top: 9rem;
          left: 8.9rem;

          @supports (container-type: size) {
            top: pxTOcqw(90);
            left: pxTOcqw(89);
          }
        }

        @include for-screen-up(1680) {
          top: 9rem;
          left: 8.9rem;
        }
      }
    }
  }
}
