.text-field {
  $field: &;

  display: inline-flex;
  vertical-align: middle;
  align-self: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
  position: relative;

  &__label {
    width: 100%;
  }

  &__control {
    background: var(--color-secondary-49);
    border: 0.1rem solid var(--filter-blue-static-2);
    border-radius: 0.7rem;
    padding: 2.5rem 2.5rem 0.9rem;

    color: var(--text-color-primary);
    font-family: Gilroy, sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.33;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.2s ease, border-color 0.2s ease,
      color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease;

    &:placeholder-shown {
      background: var(--filter-blue-static-2);
    }

    &:placeholder-shown:not(:focus) {
      padding: 1.7rem 2.5rem;
    }

    &:placeholder-shown:not(:focus) {
      padding: 1.7rem 2.5rem;
    }

    &::placeholder {
      opacity: 1;
      color: var(--text-color-secondary);
    }

    &:hover {
      outline: none;
      background-color: var(--bg-color-primary-white);
      border-color: var(--color-secondary-24);
      box-shadow: 0 0 0 0.4rem var(--color-secondary-4);
    }

    &:focus {
      outline: none;
      background-color: var(--bg-color-primary-white);
      border-color: var(--color-stroke-4);
      box-shadow: 0 0 0 0.4rem var(--color-secondary-4);
    }

    &:active {
      &:hover {
        box-shadow: none;
      }
    }

    &_invalid {
      &,
      &:placeholder-shown {
        background: var(--filter-error);
        border-color: var(--filter-error);
      }

      &:focus,
      &:hover {
        background-color: var(--bg-color-primary-white);
        border-color: var(--color-primary-error);
        box-shadow: 0 0 0 0.4rem var(--filter-error);
      }
    }
  }

  &__label-text {
    pointer-events: none;
    position: absolute;
    top: 1.1rem;
    left: 2.5rem;
    right: 2.5rem;
    order: -1;
    font-family: Gilroy, sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--text-color-secondary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.33;
    height: auto;
    transition: top 0.2s ease, color 0.2s ease, font-size 0.1s ease,
      height 0.2s ease;

    #{$field}__control_invalid ~ & {
      color: var(--text-color-error);
    }

    #{$field}__control:placeholder-shown:not(:focus) ~ & {
      top: 0;
      white-space: normal;
      height: 5.5rem;
      display: flex;
      align-items: center;
      color: var(--text-color-secondary);
      font-size: 1.5rem;
    }

    #{$field}__control:hover ~ & {
      color: var(--text-color-primary);
    }
  }

  &__helper {
    width: 100%;
    padding: 0 2.1rem;
    box-sizing: border-box;
  }

  &__error {
    position: relative;
    transition: margin-top 0.2s ease 0.2s;
    animation: Error 0.2s ease both;
    will-change: margin-top, opacity;
    opacity: 0;
  }
}

@keyframes Error {
  0% {
    margin-top: -2.4rem;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
