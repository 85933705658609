@import '../../styles/media-queries';

.section {
  $section: &;

  overflow: hidden;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  margin: -3rem 0 3.9rem;

  @include for-screen-up(640) {
    margin: -5rem 0 3.9rem;
  }

  @include for-screen-up(960) {
    margin-bottom: 6rem;
  }

  @include for-screen-up(1024) {
    margin-bottom: 9.5rem;
  }

  &__inner {
    background-color: var(--bg-color-primary-white);
    clip-path: inset(0 -2.1rem 0 -2.1rem round 8rem 8rem 0 0);

    @include for-screen-up(640) {
      clip-path: inset(0 -3rem 0 -3rem round 13.1rem 13.1rem 0 0);
    }

    @include for-screen-up(1024) {
      clip-path: inset(0 -3rem 0 -3rem round 14rem 14rem 0 0);
    }
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: column;
    box-sizing: border-box;
    margin: auto;

    @include for-screen-up(960) {
      flex-direction: row;
      align-items: center;
      padding: 7.3rem 3.5rem 0;
    }

    @include for-screen-up(1024) {
      padding: 8.2rem 4.1rem 0;
    }

    @include for-screen-up(1280) {
      padding: 9.2rem 5.2rem 0;
    }

    @include for-screen-up(1680) {
      width: 160rem;
      padding: 9.2rem 13rem 0 17.1rem;
    }

    @include for-screen-up(1920) {
      width: 184rem;
      padding: 9.2rem 12.8rem 0 17.1rem;
    }
  }

  &__column {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    min-height: inherit;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:first-child {
      padding: 7.7rem 1.8rem 3.3rem;

      @include for-screen-up(640) {
        padding: 5.9rem 6rem 4rem;
      }

      @include for-screen-up(960) {
        padding: 0 4rem 0 0;
        min-width: 50%;
        max-width: 50%;
        flex: 0 1 auto;
      }

      @include for-screen-up(1024) {
        padding-right: 5rem;
      }

      @include for-screen-up(1366) {
        padding-right: 6.3rem;
      }

      @include for-screen-up(1680) {
        padding-right: 8.8rem;
      }

      @include for-screen-up(1920) {
        padding-right: 19.7rem;
        min-width: 54%;
        max-width: 54%;
      }

      & #{$section}__column-inner {
        width: 100%;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 1 auto;

      @include for-screen-up(640) {
        min-width: 52rem;
        max-width: 52rem;
        align-self: center;
      }

      @include for-screen-up(960) {
        min-width: 50%;
        max-width: 50%;
      }

      @include for-screen-up(1920) {
        min-width: 46%;
        max-width: 46%;
      }

      & #{$section}__column-inner {
        background: #e1f3ff;
        border-radius: 4rem;

        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 116%;

        @include for-screen-up(960) {
          border-radius: 6rem;
        }

        @include for-screen-up(1024) {
          border-radius: 8.6rem;
        }

        @include for-screen-up(1680) {
          border-radius: 9rem;
        }

        @include for-screen-up(1920) {
          border-radius: 9.6rem;
        }

        svg {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__summary {
    font-family: Gilroy, sans-serif;
    margin-bottom: 4rem;
    font-size: 1.7rem;

    line-height: 1.7;

    @include for-screen-up(360) {
      font-size: 2rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 3.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      font-size: 1.6rem;
    }

    @include for-screen-up(1024) {
      font-size: 1.6rem;
    }

    @include for-screen-up(1280) {
      margin-bottom: 4.1rem;
      font-size: 2.1rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 4rem;
    }

    @include for-screen-up(1680) {
      font-size: 2.1rem;
      margin-bottom: 4.1rem;
    }

    @include for-screen-up(1920) {
      font-size: 2.2rem;
      margin-bottom: 4.1rem;
    }
  }

  &__auth-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: flex-start;

    @include for-screen-up(640) {
      flex-direction: row;
    }
  }

  &__button-link {
    flex: 0 1 auto;
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
      flex: 1 1 auto;
      align-self: flex-start;
    }
  }

  &__heading {
    color: var(--text-color-primary-black);
    margin: 0 0 1rem;
    font-size: 2.9rem;

    font-weight: normal;
    line-height: 1.4;

    @include for-screen-up(640) {
      margin-bottom: 5.5rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 1.2rem;
      font-size: 3.1rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 1.5rem;
      font-size: 3.2rem;
    }

    @include for-screen-up(1280) {
      margin-bottom: 1.5rem;

      font-size: 4rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 1.5rem;
      font-size: 4rem;
    }

    @include for-screen-up(1920) {
      margin-bottom: 1.6rem;
      font-size: 4.5rem;
    }
  }

  &__benefits {
    width: 100%;
    height: 100%;
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
  }
}
