.confirm-email {
  &__heading {
    margin: 0 0 1rem;
    color: var(--text-color-primary-black);
    font-size: 3.3rem;
    line-height: 1.36;
  }

  &__summary {
    margin-bottom: 9.6rem;
    font-family: Gilroy, sans-serif;
    color: var(--text-color-primary-black);
    font-size: 1.9rem;
    line-height: 1.7;
  }
}
