@import '../../styles/media-queries';

.network-card {
  $card: &;
  background-color: var(--bg-color-primary-white);
  border-radius: 3.2rem;
  padding: 3rem;
  box-sizing: border-box;

  @include for-screen-up(1280) {
    padding: 5rem;
  }

  &__heading {
    border-radius: 3.2rem 3.2rem 0 0;
    height: 16rem;
    margin: -3rem -3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3rem 3rem 1.6rem;
    box-sizing: border-box;
    transition: height 0.3s ease, padding 0.3s ease;

    @include for-screen-up(960) {
      height: 15.5rem;
      margin: -3rem -3rem 0;
      padding: 3.5rem 4rem 1.6rem;
    }

    @include for-screen-up(1024) {
      height: 16.5rem;
      margin: -3rem -3rem 0;
      padding: 3.5rem 4rem 1.6rem;
    }

    @include for-screen-up(1280) {
      height: 19.7rem;
      margin: -5rem -5rem 0;
      padding: 3.9rem 5rem 2.3rem;
    }

    #{$card}:hover & {
      @media (hover: hover) {
        height: 12.5rem;
        padding: 1.2rem 3rem 1.4rem;

        @include for-screen-up(1280) {
          height: 13rem;
          padding: 2.1rem 5rem 1.9rem;
        }
      }
    }
  }

  &__heading-text {
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.7;
    transition: font-size 0.3s ease;

    @include for-screen-up(1280) {
      font-size: 1.9rem;
    }

    #{$card}:hover & {
      @media (hover: hover) {
        font-size: 1.6rem;

        @include for-screen-up(1280) {
          font-size: 1.7rem;
        }
      }
    }
  }

  &__summary {
    margin: 0 -2.6rem 3rem 0;
    height: 24rem;
    padding: 3rem 2.4rem 3rem 0;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--text-color-primary-black);
    font-family: Gilroy, sans-serif;
    font-size: 1.5rem;
    line-height: 1.7;
    transition: height 0.3s ease;

    @include for-screen-up(1280) {
      height: 25.2rem;
      margin: 0 -4.5rem 3rem 0;
    }

    & > div > div {
      margin-bottom: 1.6rem;

      @include for-screen-up(1366) {
        margin-bottom: 1.4rem;
      }
    }

    & h4 {
      margin-bottom: 0.4rem;
      font-family: Gilroy, sans-serif;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.7;
    }

    #{$card}:hover & {
      @media (hover: hover) {
        height: 27.5rem;
        overflow-y: auto;
        overflow-x: hidden;

        @include for-screen-up(1280) {
          height: 31.9rem;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--color-stroke-2);
        }
      }
    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-button {
      background: transparent;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-stroke-2);
      border-radius: 0.8rem;

      @media (hover: hover) {
        background: transparent;
      }
    }
  }

  &__heading-icon {
    transition: transform 0.3s ease;
    transform-origin: 0 0;
    transform: scale(1.1);

    @include for-screen-up(640) {
      transform: scale(1.1);
    }

    @include for-screen-up(960) {
      transform: scale(0.75);
    }

    @include for-screen-up(1024) {
      transform: scale(0.9);
    }

    @include for-screen-up(1280) {
      transform: scale(1);
    }

    @include for-screen-up(1920) {
      transform: scale(1.1);
    }

    #{$card}:hover & {
      @media (hover: hover) {
        transform: scale(0.85);
      }
    }
  }

  &__button {
    width: 100%;

    @include for-screen-up(768) {
      width: auto;
    }
  }
}
