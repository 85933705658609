@import '../../styles/media-queries';

.section {
  $section: &;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &__content {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 8.2rem);
    align-items: stretch;

    @include for-screen-up(1024) {
      min-height: calc(100vh - 8.8rem);
    }

    @include for-screen-up(1024) {
      min-height: calc(100vh - 12rem);
    }
  }

  &__column {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    min-height: inherit;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:first-child {
      padding: 7.7rem 1.8rem 0;
      opacity: 0;
      will-change: opacity;
      transition: opacity 1s ease 0.9s;

      #{$section}_animation-start & {
        opacity: 1;
      }

      @include for-screen-up(640) {
        padding: 7.7rem 9.69vw 0;
      }

      @include for-screen-up(960) {
        padding: 7.7rem 0 0;
        align-items: center;
        justify-content: center;

        flex: 0 1 auto;
        min-width: 44%;
        max-width: 44%;
      }

      @include for-screen-up(1024) {
        padding: 8.2rem 0 0;
        min-width: 46%;
        max-width: 46%;
      }

      @include for-screen-up(1280) {
        min-width: 44%;
        max-width: 44%;
      }

      @include for-screen-up(1366) {
        min-width: 46%;
        max-width: 46%;
      }

      @include for-screen-up(1680) {
        min-width: 44%;
        max-width: 44%;
      }

      @include for-screen-up(1920) {
        min-width: 48%;
        max-width: 48%;
      }

      @include for-screen-up(2560) {
        min-width: 49%;
        max-width: 49%;
      }

      & #{$section}__column-inner {
        width: 100%;

        @include for-screen-up(640) {
          width: 42rem;
        }

        @include for-screen-up(960) {
          width: 32rem;
        }

        @include for-screen-up(1024) {
          width: 38rem;
        }

        @include for-screen-up(1366) {
          width: 41rem;
        }

        @include for-screen-up(1680) {
          width: 47rem;
        }

        @include for-screen-up(1920) {
          width: 52rem;
        }

        @include for-screen-up(2560) {
          width: 65rem;
        }
      }
    }

    &:last-child {
      display: none;

      &:before {
        content: '';
        background: #e1f3ff;
        border-radius: 0 0 0 4rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        transform: translate3d(100%, 0, 0);
        will-change: transfrom;
        transition: transform 1s ease 0.6s;

        #{$section}_animation-start & {
          transform: translate3d(0%, 0, 0);
        }
      }

      @include for-screen-up(960) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 auto;
        min-width: 56%;
        max-width: 56%;
        //padding: 7.7rem 0 0;
      }

      @include for-screen-up(1024) {
        min-width: 54%;
        max-width: 54%;
      }

      @include for-screen-up(1280) {
        min-width: 56%;
        max-width: 56%;
      }

      @include for-screen-up(1366) {
        min-width: 54%;
        max-width: 54%;
      }

      @include for-screen-up(1680) {
        min-width: 56%;
        max-width: 56%;
      }

      @include for-screen-up(1920) {
        min-width: 52%;
        max-width: 52%;
      }

      @include for-screen-up(2560) {
        min-width: 51%;
        max-width: 51%;
      }

      & #{$section}__column-inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        will-change: opacity;
        transition: opacity 1s ease 1.1s;

        #{$section}_animation-start & {
          opacity: 1;
        }
      }
    }
  }

  &__summary {
    color: var(--text-color-primary-black);
    font-family: Gilroy, sans-serif;
    margin-bottom: 3.5rem;
    font-size: 1.7rem;

    line-height: 1.6;

    @include for-screen-up(360) {
      font-size: 2rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 3.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      font-size: 1.6rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 4rem;

      font-size: 1.6rem;
    }

    @include for-screen-up(1366) {
    }

    @include for-screen-up(1680) {
      font-size: 2.1rem;
    }

    @include for-screen-up(2560) {
      margin-bottom: 5rem;
      font-size: 2.6rem;
    }
  }

  &__auth-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include for-screen-up(640) {
      flex-direction: row;
    }
  }

  &__button-link {
    flex: 0 1 auto;
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }

  &__heading {
    color: var(--text-color-primary-black);
    margin: 0 0 1rem;
    font-size: 3.2rem;

    font-weight: normal;
    line-height: 1.3;

    @include for-screen-up(360) {
      font-size: 3.6rem;
    }

    @include for-screen-up(640) {
      font-size: 4rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 0.6rem;
      font-size: 3.3rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 1rem;
      font-size: 4rem;
    }

    @include for-screen-up(1366) {
      font-size: 4rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 1.5rem;
      font-size: 4.5rem;
    }

    @include for-screen-up(2560) {
      margin-bottom: 1.875rem;
      font-size: 5.6rem;
    }
  }

  &__hero {
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    @include for-screen-up(960) {
      display: block;
    }
  }

  &__hero-man {
    background: url('/images/networks/bg/man.png') no-repeat;
    background-size: 100% auto;
    width: 31.1rem;
    height: 28.9rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -44%);
    transition: transform 0.4s ease 1.5s;

    #{$section}_animation-start & {
      transform: translate(-4.7rem, -44%);
    }

    @include for-screen-up(1024) {
      #{$section}_animation-start & {
        transform: translate(-4.7rem, -48%);
      }
    }

    @include for-screen-up(1280) {
      width: 36.5rem;
      height: 33.9rem;

      #{$section}_animation-start & {
        transform: translate(-4.8rem, -46%);
      }
    }

    @include for-screen-up(1366) {
      #{$section}_animation-start & {
        transform: translate(-4.8rem, -54%);
      }
    }

    @include for-screen-up(1680) {
      width: 44.3rem;
      height: 41.2rem;
      transform: translate(-100%, -50%);

      #{$section}_animation-start & {
        transform: translate(-6.4rem, -50%);
      }
    }

    @include for-screen-up(1920) {
      left: 0;

      #{$section}_animation-start & {
        transform: translate(0, -45%);
      }
    }

    @include for-screen-up(2560) {
      width: 54.2rem;
      height: 50.4rem;
      transform: translate(-100%, -52%);

      #{$section}_animation-start & {
        transform: translate(0%, -52%);
      }
    }
  }

  &__hero-square {
    border-radius: 2.8rem;
    width: 0;
    height: 22.1rem;
    position: absolute;
    overflow: hidden;
    top: 50%;
    transform: translate(26.39rem, -48%);

    left: 0;
    transition: width 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8) 1.7s;

    #{$section}_animation-start & {
      width: 21.4rem;
    }

    @include for-screen-up(1024) {
      transform: translate(26.39rem, -51%);
    }

    @include for-screen-up(1280) {
      width: 0;
      height: 25.9rem;
      transform: translate(31.4rem, -49%);

      border-radius: 3.3rem;

      #{$section}_animation-start & {
        width: 25.1rem;
      }
    }

    @include for-screen-up(1366) {
      transform: translate(31.4rem, -60%);
    }

    @include for-screen-up(1680) {
      width: 0;
      height: 31.5rem;
      border-radius: 4rem;
      transform: translate(37.6rem, -55%);

      #{$section}_animation-start & {
        width: 30rem;
      }
    }

    @include for-screen-up(1920) {
      transform: translate(44rem, -48%);

      #{$section}_animation-start & {
        width: 30.5rem;
      }
    }

    @include for-screen-up(2560) {
      width: 0;
      height: 38.3rem;
      border-radius: 4.9rem;
      transform: translate(53.8rem, -53%);

      #{$section}_animation-start & {
        width: 37.3rem;
      }
    }
  }

  &__hero-name {
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    color: inherit;
    font-size: 1.3rem;
    line-height: 1.7;
    position: absolute;
    bottom: 2.45rem;
    left: 3.51rem;

    @include for-screen-up(1280) {
      font-size: 1.6rem;
      bottom: 3.05rem;
      left: 4.11rem;
    }

    @include for-screen-up(1680) {
      bottom: 3.65rem;
      left: 4.91rem;
    }

    @include for-screen-up(1920) {
      font-size: 1.9rem;
    }

    @include for-screen-up(2560) {
      font-size: 2.3rem;
    }
  }

  &__hero-icon {
    position: absolute;
    top: 2.8rem;
    left: 3.16rem;

    @include for-screen-up(1280) {
      top: 3.1rem;
      left: 3.46rem;
    }

    @include for-screen-up(1680) {
      top: 4rem;
      left: 4.56rem;
    }

    svg {
      display: block;
      transform: scale(0.6);
      transform-origin: 0 0;

      @include for-screen-up(1280) {
        transform: scale(0.7);
      }

      @include for-screen-up(1680) {
        transform: scale(0.8);
      }

      @include for-screen-up(2560) {
        transform: scale(1);
      }
    }
  }

  &__hero-dollar1 {
    position: absolute;
    top: 77%;
    left: 0;
    transition: transform 0.5s ease 2.2s;
    transform: translateX(-100%);

    #{$section}_animation-start & {
      transform: translateX(26rem);
    }

    @include for-screen-up(1024) {
      top: 75%;

      #{$section}_animation-start & {
        transform: translateX(26rem);
      }
    }

    @include for-screen-up(1280) {
      top: 80%;

      #{$section}_animation-start & {
        transform: translateX(31rem);
      }
    }

    @include for-screen-up(1366) {
      top: 74%;

      #{$section}_animation-start & {
        transform: translateX(31rem);
      }
    }

    @include for-screen-up(1680) {
      top: 76%;

      #{$section}_animation-start & {
        transform: translateX(36rem);
      }
    }

    @include for-screen-up(1920) {
      top: 81%;

      #{$section}_animation-start & {
        transform: translateX(43.5rem);
      }
    }

    @include for-screen-up(2560) {
      #{$section}_animation-start & {
        transform: translateX(53.5rem);
      }
    }

    svg {
      transform: scale(0.7);
      transform-origin: 0 0;

      @include for-screen-up(1280) {
        transform: scale(0.8);
      }

      @include for-screen-up(1680) {
        transform: scale(1);
      }

      @include for-screen-up(1920) {
        transform: scale(1);
      }
    }
  }

  &__hero-dollar2 {
    position: absolute;
    top: 19%;
    left: 0;

    transition: transform 0.5s ease 1.8s;
    transform: translateX(-130%);

    #{$section}_animation-start & {
      transform: translateX(46.6rem);
    }

    @include for-screen-up(1280) {
      top: 20%;

      #{$section}_animation-start & {
        transform: translateX(62rem);
      }
    }

    @include for-screen-up(1366) {
      top: 19%;

      #{$section}_animation-start & {
        transform: translateX(61rem);
      }
    }

    @include for-screen-up(1680) {
      top: 22%;

      #{$section}_animation-start & {
        transform: translateX(78.6rem);
      }
    }

    @include for-screen-up(1920) {
      top: 24%;
      #{$section}_animation-start & {
        transform: translateX(86rem);
      }
    }

    @include for-screen-up(2560) {
      top: 24%;

      #{$section}_animation-start & {
        transform: translateX(100rem);
      }
    }

    svg {
      transform: scale(1);
      transform-origin: 0 0;

      @include for-screen-up(1280) {
        transform: scale(1.2);
      }

      @include for-screen-up(1680) {
        transform: scale(1.4);
      }

      @include for-screen-up(1920) {
        transform: scale(1.4);
      }
    }
  }

  &__hero-coin {
    position: absolute;
    top: 73%;
    left: 0;

    transition: transform 0.5s ease 2s;
    transform: translateX(-100%);

    #{$section}_animation-start & {
      transform: translateX(43.9rem);
    }

    @include for-screen-up(1024) {
      top: 71%;
    }

    @include for-screen-up(1280) {
      top: 75%;

      #{$section}_animation-start & {
        transform: translateX(51.9rem);
      }
    }

    @include for-screen-up(1366) {
      top: 70%;

      #{$section}_animation-start & {
        transform: translateX(51.9rem);
      }
    }

    @include for-screen-up(1680) {
      top: 72%;

      #{$section}_animation-start & {
        transform: translateX(61.9rem);
      }
    }

    @include for-screen-up(1920) {
      top: 77%;

      #{$section}_animation-start & {
        transform: translateX(69.1rem);
      }
    }

    @include for-screen-up(2560) {
      top: 71%;

      #{$section}_animation-start & {
        transform: translateX(86.1rem);
      }
    }

    svg {
      transform: scale(0.7);
      transform-origin: 0 0;

      @include for-screen-up(1280) {
        transform: scale(0.8);
      }

      @include for-screen-up(1680) {
        transform: scale(1);
      }

      @include for-screen-up(1920) {
        transform: scale(1);
      }
    }
  }
}
