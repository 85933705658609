@import '../../styles/media-queries';

.tag {
  background: var(--bg-color-primary-darkgrey);
  border-radius: 1.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.35rem 1.03rem;

  font-family: Gilroy, sans-serif;
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.7;
  gap: 0.56rem;

  @include for-screen-up(640) {
    font-size: 0.9rem;
    padding: 0.35rem 1.2rem;
  }

  @include for-screen-up(1024) {
    font-size: 0.95rem;
    padding: 0.35rem 1.3rem;
  }

  @include for-screen-up(1366) {
    font-size: 1.2rem;
    padding: 0.35rem 1.4rem;
    gap: 0.8rem;
  }

  @include for-screen-up(1680) {
    padding: 0.4rem 1.7rem;
    font-size: 1.2rem;
    gap: 0.9rem;
  }
}
