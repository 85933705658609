@import '../../styles/media-queries';

.benefit {
  $benefit: &;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  &__preview {
    width: 100%;
    position: relative;
  }

  &__preview-in {
    padding-bottom: 96%;
    position: relative;

    img {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    svg {
      width: 120% !important;
      height: auto !important;
      margin: auto;
      position: absolute;
      top: 0;
      left: -10%;
      bottom: 0;
    }
  }
}

.lottie {
  margin: auto;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}
