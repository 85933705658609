@import '../../styles/media-queries';
@import '../../styles/functions';

.section {
  $section: &;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &__content {
    display: flex;
    width: 100%;
    align-items: stretch;
    //margin-bottom: 3.6rem;
    min-height: calc(100vh - 8.2rem);

    @include for-screen-up(960) {
      min-height: calc(100vh - 8.2rem);
      margin: 0;
    }

    @include for-screen-up(1024) {
      min-height: calc(100vh - 8.8rem);
    }

    @include for-screen-up(1680) {
      min-height: calc(100vh - 12rem);
    }
  }

  &__column {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    min-height: inherit;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:first-child {
      padding: 7.7rem 1.8rem 0;

      @include for-screen-up(960) {
        opacity: 0;
        will-change: opacity;
        transition: opacity 1s ease 0.9s;
      }

      #{$section}_animation-start & {
        @include for-screen-up(960) {
          opacity: 1;
        }
      }

      @include for-screen-up(640) {
        padding: 7.7rem 9.69vw 0;
      }

      @include for-screen-up(960) {
        padding: 7.7rem 0 0;
        align-items: center;
        justify-content: center;

        flex: 0 1 auto;
        min-width: 44%;
        max-width: 44%;
      }

      @include for-screen-up(1024) {
        padding: 8.2rem 0 0;
      }

      @include for-screen-up(1366) {
        min-width: 49%;
        max-width: 49%;
      }

      & #{$section}__column-inner {
        width: 100%;

        @include for-screen-up(640) {
          width: 42rem;
        }

        @include for-screen-up(960) {
          width: 35rem;
        }

        @include for-screen-up(1366) {
          width: responsive(41.1, 57);
        }
      }
    }

    &:last-child {
      display: none;

      &:before {
        content: '';
        background: #e1f3ff;
        border-radius: 0 0 0 4rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        transform: translate3d(100%, 0, 0);
        will-change: transfrom;
        transition: transform 1s ease 0.6s;

        #{$section}_animation-start & {
          transform: translate3d(0%, 0, 0);
        }
      }

      @include for-screen-up(960) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 auto;
        min-width: 56%;
        max-width: 56%;
        padding: 7.7rem 0 0;
      }

      @include for-screen-up(1024) {
        padding: 8.2rem 0 0;
      }

      @include for-screen-up(1366) {
        min-width: 51%;
        max-width: 51%;
      }

      & #{$section}__column-inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        will-change: opacity;
        transition: opacity 1s ease 1.1s;

        #{$section}_animation-start & {
          opacity: 1;
        }
      }
    }
  }

  &__summary {
    font-family: Gilroy, sans-serif;
    margin-bottom: 3.5rem;
    font-size: 1.7rem;

    line-height: 1.6;

    @include for-screen-up(360) {
      font-size: 2rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 3.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      font-size: 1.8rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 2.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: responsive(4, 5);
      font-size: responsive(2, 2.6);
    }
  }

  &__auth-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include for-screen-up(640) {
      flex-direction: row;
    }
  }

  &__button-link {
    flex: 0 1 auto;
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }

  &__heading {
    color: var(--text-color-primary-black);
    margin: 0 0 1rem;
    font-size: 3.2rem;

    font-weight: normal;
    line-height: 1.3;

    @include for-screen-up(360) {
      font-size: 3.6rem;
    }

    @include for-screen-up(640) {
      font-size: 4rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 0.6rem;
      font-size: 3.3rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 1rem;
      font-size: 3.5rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: responsive(1, 1.875);
      font-size: responsive(4, 5.6);
    }
  }

  &__benefits {
    width: 100%;
    height: 100%;
  }
}
