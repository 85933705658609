@import '../../styles/media-queries';

.home {
  padding-bottom: 12rem;

  &__screen {
    display: flex;
    flex-direction: column;

    margin-bottom: 4rem;
    min-height: 100vh;

    @include for-screen-up(640) {
      margin-bottom: 4.4rem;
    }

    @include for-screen-up(960) {
      //min-height: 100vh;
    }

    @include for-screen-up(1024) {
      margin-bottom: 7rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 8rem;
    }
  }

  &__features {
    &:not([class*='is-visible']) {
      min-height: 108rem;
    }
  }

  &__methods {
    &:not([class*='is-visible']) {
      min-height: 87.3rem;
    }
  }

  &__program {
    &:not([class*='is-visible']) {
      min-height: 137.7rem;
    }
  }
}
