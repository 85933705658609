.error-message {
  display: inline-flex;
  padding: 0 2.1rem;
  align-items: center;
  justify-content: flex-start;
  color: var(--text-color-error);
  font-size: 1.2rem;
  line-height: 1;
  gap: 0.8rem;
}
