@import '../../styles/media-queries';

.tabs {
  $tabs: &;
  background: var(--color-secondary-5);
  border-radius: 9rem;
  padding: 0.3rem;
  position: relative;
  z-index: 1;

  @include for-screen-up(640) {
    padding: 0.4rem;
    border-radius: 8.4rem;
  }

  @include for-screen-up(1024) {
    border-radius: 9rem;
  }

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 0.3rem;

    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    @include for-screen-up(640) {
      gap: 0.4rem;
    }
  }

  &__item {
    display: flex;
    align-items: stretch;
    flex: 0 1 auto;
    width: 100%;
    min-width: 50%;
    font-size: 1.5rem;
    line-height: 1.36;

    @include for-screen-up(640) {
      width: auto;
      min-width: 0;
      font-size: 1.7rem;
    }

    @include for-screen-up(960) {
      min-width: 0;
      max-width: none;
    }
  }

  &__link {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8.2rem;
    padding: 0.65rem 2.2rem;

    box-sizing: border-box;
    color: var(--text-color-primary);

    &:hover {
      background-color: var(--filter-blue-hover);
    }

    &:active {
      &:hover {
        background-color: var(--filter-blue-active);
      }
    }

    @include for-screen-up(360) {
      padding: 0.65rem 4.2rem;
    }

    @include for-screen-up(640) {
      border-radius: 8.4rem;
      padding: 1.9rem 4.3rem;
    }

    @include for-screen-up(1024) {
      border-radius: 9rem;
      padding: 2rem 4.6rem;
    }

    &_active {
      background-color: var(--bg-color-primary-blue);
      color: var(--text-color-primary-white);

      &:hover {
        background-color: var(--bg-color-primary-blue-hover);
      }
    }
  }
}
