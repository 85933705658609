@import '../../styles/media-queries';

.section {
  &__scroll-table {
    margin: 0 -1.8rem;

    @include for-screen-up(640) {
      margin: 0 -3rem;
    }
  }

  &__heading {
    margin: 0 0 1.9rem;
    color: var(--text-color-primary-white);
    font-size: 3rem;

    line-height: 1.36;
    text-align: center;

    @include for-screen-up(400) {
      font-size: 3.6rem;
    }

    @include for-screen-up(640) {
      font-size: 4rem;
      margin-bottom: 1rem;
    }

    @include for-screen-up(960) {
      font-size: 5rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 0.9rem;
      font-size: 5.3rem;
    }

    @include for-screen-up(1680) {
      margin: 0;
      font-size: 6.5rem;
    }
  }

  &__summary {
    margin: 0 auto 4rem;
    font-family: Gilroy, sans-serif;
    font-size: 1.6rem;

    line-height: 1.7;
    color: var(--text-color-primary-white);
    text-align: center;

    @include for-screen-up(360) {
      font-size: 1.8rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 2.4rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      max-width: 60rem;
    }

    @include for-screen-up(1024) {
      max-width: 64rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 4.2rem;
      font-size: 2rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 4.7rem;
      font-size: 2.1rem;
    }
  }

  &__button {
    position: relative;
    z-index: 1;
  }

  &__notice {
    padding: 4.3rem 0 0;
    text-align: center;
    font-family: Gilroy, sans-serif;
    color: var(--text-color-secondary);
    font-size: 1.2rem;
    line-height: 1.7;
  }
}
